exports.components = {
  "component---src-page-templates-press-release-page-template-press-release-page-template-tsx": () => import("./../../../src/pageTemplates/PressReleasePageTemplate/PressReleasePageTemplate.tsx" /* webpackChunkName: "component---src-page-templates-press-release-page-template-press-release-page-template-tsx" */),
  "component---src-page-templates-text-content-page-template-text-content-page-template-tsx": () => import("./../../../src/pageTemplates/TextContentPageTemplate/TextContentPageTemplate.tsx" /* webpackChunkName: "component---src-page-templates-text-content-page-template-text-content-page-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-find-talent-with-truffls-form-tsx": () => import("./../../../src/pages/find-talent-with-truffls/form.tsx" /* webpackChunkName: "component---src-pages-find-talent-with-truffls-form-tsx" */),
  "component---src-pages-find-talent-with-truffls-index-tsx": () => import("./../../../src/pages/find-talent-with-truffls/index.tsx" /* webpackChunkName: "component---src-pages-find-talent-with-truffls-index-tsx" */),
  "component---src-pages-find-talent-with-truffls-thanks-tsx": () => import("./../../../src/pages/find-talent-with-truffls/thanks.tsx" /* webpackChunkName: "component---src-pages-find-talent-with-truffls-thanks-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mobile-recruiting-tsx": () => import("./../../../src/pages/mobile-recruiting.tsx" /* webpackChunkName: "component---src-pages-mobile-recruiting-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-trendstudy-2024-download-tsx": () => import("./../../../src/pages/trendstudy-2024/download.tsx" /* webpackChunkName: "component---src-pages-trendstudy-2024-download-tsx" */),
  "component---src-pages-trendstudy-2024-form-tsx": () => import("./../../../src/pages/trendstudy-2024/form.tsx" /* webpackChunkName: "component---src-pages-trendstudy-2024-form-tsx" */),
  "component---src-pages-trendstudy-2024-index-tsx": () => import("./../../../src/pages/trendstudy-2024/index.tsx" /* webpackChunkName: "component---src-pages-trendstudy-2024-index-tsx" */),
  "component---src-pages-whitepaper-tsx": () => import("./../../../src/pages/whitepaper.tsx" /* webpackChunkName: "component---src-pages-whitepaper-tsx" */)
}

