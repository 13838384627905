import { locales } from '../i18n/locales';
import {
    createFindRouteDefinitionByRoute,
    createRouteState,
    deprefixLocale,
    getFullPathForLocale,
    getFullPathForLocaleOrFallback,
    getPathForLocale,
    Locale,
    prefixWithLocale,
    RouteDefinition,
    RouteState
} from './utils';

export { createRouteState, deprefixLocale, getFullPathForLocale } from './utils';
export type { RouteDefinition, RouteState } from './utils';
export {
    getLocaleFromPath,
    getLocalizedMismatchedLocaleRoutesForLocaleByRoute,
    getLocalizedRouteForLocale,
    getRouteFromPath
};

export const forCandidatesRoute: RouteDefinition = {
    en: '/',
    de: '/'
};
export function getForCandidatesRoute(locale: Locale) {
    return getFullPathForLocale(createRouteState(forCandidatesRoute), locale);
}

export const forCompaniesRoute: RouteDefinition = {
    en: '/mobile-recruiting/',
    de: '/mobile-recruiting/'
};
export function getForCompaniesRoute(locale: Locale) {
    return getFullPathForLocale(createRouteState(forCompaniesRoute), locale);
}

export const aboutRoute: RouteDefinition = {
    en: '/about-us/',
    de: '/ueber-uns/'
};
export function getAboutRoute(locale: Locale) {
    return getFullPathForLocale(createRouteState(aboutRoute), locale);
}

export const careerRoute: RouteDefinition = {
    en: '/career/',
    de: '/karriere/'
};
export function getCareerRoute(locale: Locale) {
    return getFullPathForLocale(createRouteState(careerRoute), locale);
}

export const pressRoute: RouteDefinition = {
    en: '/press/',
    de: '/presse/'
};
export function getPressRoute(locale: Locale) {
    return getFullPathForLocale(createRouteState(pressRoute), locale);
}

export const whitepaperRoute: RouteDefinition = {
    en: '/whitepaper/',
    de: '/whitepaper/'
};
export function getWhitepaperRoute(locale: Locale) {
    return getFullPathForLocale(createRouteState(whitepaperRoute), locale);
}

export const trendstudy2024Route: RouteDefinition = {
    en: '/trendstudy-2024/',
    de: '/trendstudie-2024/'
};
export function getTrendstudy2024Route(locale: Locale) {
    return getFullPathForLocale(createRouteState(trendstudy2024Route), locale);
}

export const trendstudy2024FormRoute: RouteDefinition = {
    en: '/trendstudy-2024/form/',
    de: '/trendstudie-2024/form/'
};
export function getTrendstudy2024FormRoute(locale: Locale) {
    return getFullPathForLocale(createRouteState(trendstudy2024FormRoute), locale);
}

export const trendstudy2024DownloadRoute: RouteDefinition = {
    en: '/trendstudy-2024/download/',
    de: '/trendstudie-2024/download/'
};
export function getTrendstudy2024DownloadRoute(locale: Locale) {
    return getFullPathForLocale(createRouteState(trendstudy2024DownloadRoute), locale);
}

export const linkedinCampaign202504Route: RouteDefinition = {
    en: '/find-talent-with-truffls/',
    de: '/talente-mit-truffls-finden/'
};
export function getLinkedinCampaign202504Route(locale: Locale) {
    return getFullPathForLocale(createRouteState(linkedinCampaign202504Route), locale);
}

export const linkedinCampaign202504FormRoute: RouteDefinition = {
    en: `${linkedinCampaign202504Route.en}form/`,
    de: `${linkedinCampaign202504Route.de}form/`
};
export function getLinkedinCampaign202504FormRoute(locale: Locale) {
    return getFullPathForLocale(createRouteState(linkedinCampaign202504FormRoute), locale);
}

export const linkedinCampaign202504ThanksRoute: RouteDefinition = {
    en: `${linkedinCampaign202504Route.en}thanks/`,
    de: `${linkedinCampaign202504Route.de}danke/`
};
export function getLinkedinCampaign202504ThanksRoute(locale: Locale) {
    return getFullPathForLocale(createRouteState(linkedinCampaign202504ThanksRoute), locale);
}

export const faqRoute: RouteDefinition = {
    en: '/faq/',
    de: '/faq/'
};
export function getFaqRoute(locale: Locale) {
    return getFullPathForLocale(createRouteState(faqRoute), locale);
}

export const productsRoute: RouteDefinition = {
    en: '/products/',
    de: '/products/'
};
export function getProductsRoute(locale: Locale) {
    return getFullPathForLocale(createRouteState(productsRoute), locale);
}

export const imprintRoute: RouteDefinition = {
    en: '/imprint/',
    de: '/impressum/'
};
export function getImprintRoute(locale: Locale) {
    return getFullPathForLocale(createRouteState(imprintRoute), locale);
}

export const privacyRoute: RouteDefinition = {
    en: '/privacy/',
    de: '/datenschutz/'
};
export function getPrivacyRoute(locale: Locale) {
    return getFullPathForLocale(createRouteState(privacyRoute), locale);
}

export const gtcRoute: RouteDefinition = {
    en: '/gtc/',
    de: '/agb/'
};
export function getGtcRoute(locale: Locale) {
    return getFullPathForLocale(createRouteState(gtcRoute), locale);
}

export const pressReleaseRoute: RouteDefinition = {
    en: '/press/release/:date/',
    de: '/presse/mitteilungen/:date/'
};
export function getPressReleaseRoute(locale: Locale, date: string) {
    return getFullPathForLocale(createRouteState(pressReleaseRoute, { date }), locale);
}

export const trufflsJoinsFunkeWorksRoute: RouteDefinition = {
    en: '/c/pq8djxy6h2/',
    de: '/c/pq8djxy6h2/'
};
export function getTrufflsJoinsFunkeWorksRoute(locale: Locale) {
    return getFullPathForLocale(createRouteState(trufflsJoinsFunkeWorksRoute), locale);
}

const findRouteDefinitionByRoute = createFindRouteDefinitionByRoute([
    forCandidatesRoute,
    forCompaniesRoute,
    aboutRoute,
    careerRoute,
    pressRoute,
    whitepaperRoute,
    faqRoute,
    productsRoute,
    imprintRoute,
    privacyRoute,
    gtcRoute,
    pressReleaseRoute,
    trendstudy2024Route,
    trendstudy2024FormRoute,
    trendstudy2024DownloadRoute,
    linkedinCampaign202504Route,
    linkedinCampaign202504FormRoute,
    linkedinCampaign202504ThanksRoute
]);

/**
 * Takes the route path (without locale prefix) and returns the full URL of the route for the given locale.
 */
function getLocalizedRouteForLocale(unlocalizedRouteToMatch: string, locale: string): string {
    const routeState = findRouteDefinitionByRoute(unlocalizedRouteToMatch);

    return getFullPathForLocaleOrFallback(routeState ?? unlocalizedRouteToMatch, locale);
}

/**
 * Takes the route path (without locale prefix) and returns URLs that contain the combination of the route for the
 * given locale combined with the other localed of the route.
 * @example
 * const state = {
 *    definition: {
 *        en: '/about-us/',
 *        de: '/ueber-uns/',
 *        fr: '/sur-nous/'
 *    },
 *    parameters: {}
 * };
 * getLocalizedMismatchedLocaleRoutesForLocale(state, 'en')
 * // => ['/en/ueber-uns/', '/en/sur-nous/']
 */
export function getLocalizedMismatchedLocaleRoutesForLocale(routeState: RouteState, locale: Locale): string[] {
    const { definition: sourceRouteDefinition } = routeState;

    const correctLocaleRoute = getFullPathForLocale(routeState, locale);

    const otherRouteLocales = Object.keys(sourceRouteDefinition).filter((routeLocale) => {
        return routeLocale !== locale;
    });

    return otherRouteLocales
        .map((otherRouteLocale) => {
            const otherLocalePathAfterLocale = getPathForLocale(routeState, otherRouteLocale);
            const randomLocaleRoute = prefixWithLocale(locale, otherLocalePathAfterLocale);

            return correctLocaleRoute === randomLocaleRoute ? null : randomLocaleRoute;
        })
        .filter((randomLocaleRoute): randomLocaleRoute is string => {
            return randomLocaleRoute !== null;
        });
}

/**
 * Takes the route path (without locale prefix) and returns URLs that contain the combination of the route for the
 * given locale combined with the other localed of the route.
 * @example
 * const def = {
 *    en: '/about-us/',
 *    de: '/ueber-uns/',
 *    fr: '/sur-nous/'
 * };
 * getLocalizedMismatchedLocaleRoutesForLocaleByRoute('/about-us/', 'en')
 * // => ['/en/ueber-uns/', '/en/sur-nous/']
 */
function getLocalizedMismatchedLocaleRoutesForLocaleByRoute(route: string, locale: Locale): string[] {
    const routeState = findRouteDefinitionByRoute(route);

    if (!routeState) {
        return [];
    }

    return getLocalizedMismatchedLocaleRoutesForLocale(routeState, locale);
}

export const createGetLocaleFromPathname = ({ locales }) => {
    return (pathname) => {
        const cleanPathname = pathname.substr(0, 1) === '/' ? pathname.substr(1) : pathname;
        const pathnameParts = cleanPathname.split('/');
        const localeFromPathname = pathnameParts[0];

        if (locales.includes(localeFromPathname)) {
            return localeFromPathname;
        }

        return null;
    };
};

const getLocaleFromPath = createGetLocaleFromPathname({ locales });

const getRouteFromPath = (path: string) => {
    const route = deprefixLocale(path);
    const routeWithoutTrailingSlash = route !== '/' && route.slice(-1) === '/' ? route.slice(0, -1) : route;

    return routeWithoutTrailingSlash;
};

export function getAlternateLocalePath(sourcePath: string, locale: Locale): string {
    const sourcePathWithoutLocale = deprefixLocale(sourcePath);
    const routeState = findRouteDefinitionByRoute(sourcePathWithoutLocale);

    if (!routeState) {
        return prefixWithLocale(locale, sourcePathWithoutLocale);
    }

    return getFullPathForLocale(routeState, locale);
}
